@import "../../../assets/scss/abstracts/abstracts";

.select-section {
  margin-top: 32px;
  label {
    font-size: fontSize(12px);
    color: $blue-2c;
    line-height: 15px;
    padding-bottom: 6px;
  }
  .Select--is-disabled {
    border-radius: 4px;
    font-size: 14px;
    line-height: 18px;
    color: $text-black-1a;
    opacity: 0.9;
    background-color: $white;
    cursor: not-allowed;
  }
  /* the modification */
  option:first-of-type {
    display: none;
  }
  i {
    position: absolute;
    right: 10px;
    top: 10px;
    pointer-events: none;
    &.icon-arrow-down-filled {
      top: 5px;
      right: 0;
    }
  }
}

.table-select_dropdown {
  .select-section {
    margin-top: 0;
    width: 100%;
    .Select__control {
      height: 34px;
    }
  }
}

@import "../../../assets/scss/abstracts/abstracts";

.disabled {
  pointer-events: none;
}
.pagination {
  .page-item {
    background: $white;
    border: 0.75px solid #c7c7c7;
    font-size: 12px;
    margin: 0 3px;
    a {
      color: $secondary-color;
      margin-left: 0;
      line-height: 15px;
      opacity: 0.6;
      height: 35px;
      width: 35px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &.active {
      a {
        color: $white;
        opacity: 1;
        background: #1a4ba2;
        border-color: transparent;
      }
    }
  }
  button {
    border: 0;
    height: 35px;
    padding: 0 15px;
    display: flex;
    align-items: center;
    &.btn-effect {
      background: none;
    }
  }
  .prev,
  .next {
    background: #f5f5f5;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    color: #1a4ba2;
    margin: 0 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    a {
      color: #1a4ba2;
      height: 36px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &.disabled {
      a {
        color: $black;
        opacity: 0.2;
      }
    }
  }
  .page-link:focus {
    z-index: 3;
    outline: 0;
    box-shadow: 0 0;
  }
  button:focus {
    outline: none;
  }
}

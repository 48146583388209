$primary-color: #1a4ba1;
$secondary-color: #2d3748;
$white: #fff;
$green-18: #189c5c;
$black: #000;
$grey-91: #91919c;
$blue-2c: #2c5282;
$red: #f34141;
$success-00: #00b050;
$text-black-1a: #1a202c;

.text-black {
  color: $black;
}

.text-black-1a {
  color: $text-black-1a;
}

.text-success-00 {
  color: $success-00;
}

.text-secondary {
  color: $secondary-color;
}

.text-blue-2c {
  color: $blue-2c;
}
.text-primaryColor {
  color: $primary-color !important;
}

$c1: #1a4ba1;
$c2: #2d3748;

$myColors: (
  "c1": $c1,
  "c2": $c2,
);

@each $name, $colors in $myColors {
  $index: index($myColors, $colors);
  .text-#{$name} {
    color: $colors;
  }
  .bg-#{$name} {
    background-color: $colors;
  }
  .text-#{$name} {
    color: $colors;
  }
  .bg-h-#{$name} {
    background-color: $colors;
  }
  .bc-#{$name} {
    border-color: $colors;
  }
}

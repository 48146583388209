@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?y4f6mm');
  src:  url('fonts/icomoon.eot?y4f6mm#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?y4f6mm') format('truetype'),
    url('fonts/icomoon.woff?y4f6mm') format('woff'),
    url('fonts/icomoon.svg?y4f6mm#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

i {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-down-filled:before {
  content: "\e904";
}
.icon-eye:before {
  content: "\e903";
}
.icon-arrow-down:before {
  content: "\e900";
}
.icon-eye-hide:before {
  content: "\e901";
}
.icon-mail:before {
  content: "\e902";
}
.icon-edit:before {
  content: "\e91d";
}
.icon-profile:before {
  content: "\e91b";
}
.icon-notification:before {
  content: "\e90b";
}
.icon-close-round:before {
  content: "\e90a";
}
.icon-close:before {
  content: "\e911";
  color: #4d4d4d;
}
.icon-arrow-left:before {
  content: "\e90c";
}
.icon-search:before {
  content: "\e909";
}
.icon-tick-transparent:before {
  content: "\e90e";
  color: #fff;
}
.icon-Delete:before {
  content: "\e914";
}

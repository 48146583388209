@import "../../../assets/scss/abstracts/abstracts";

// .table-container{
//   width: 1270px;
// }
.maintable {
  .btn {
    height: 36px;
  }
  // .table {
  //   td,
  //   th {
  //     // vertical-align: middle;
  //   }
  // }
  thead {
    background: $c1;
    border-radius: 4px;
    margin-bottom: 20px;
    margin: 0px;
    tr {
      th {
        font-family: "DM Sans", sans-serif;
        div {
          font-family: "DM Sans", sans-serif;
          line-height: 18px;
        }
      }
    }
  }
  tbody {
    border: 0.75px solid #dcdcdc;
    tr:nth-child(even) {
      background: #fbfbfb;
    }
    tr {
      background: $white;
      td {
        font-family: "DM Sans", sans-serif;
        div {
          font-family: "DM Sans", sans-serif;
        }
      }
      &:hover {
        background-color: #f4f4f4;
      }
    }
  }
  .table {
    border: 0.75px solid #dcdcdc;
    th {
      font-weight: normal;
      color: $white;
      font-size: fontSize(14px);
      vertical-align: middle;
      font-weight: 700;
    }
    td {
      padding: 10px 20px;
      color: $black;
      font-size: fontSize(12px);
      border-top: 0.5px solid #f7f7f7;
    }
  }

  button {
    border: 0;
    height: 35px;
    padding: 0 15px;
    display: flex;
    align-items: center;

    &.btn-effect {
      background: none;
    }
  }
  button:focus {
    outline: none;
  }
}
.div-pagination {
  display: flex;
  justify-content: space-between;
  .show-product span {
    font-size: 18px;
    color: #575757;
  }
  .show-product {
    select {
      border: 0.5px solid #d2d2d2;
      background: #fff;
      padding: 3px;
      font-size: 18px;
      color: #575757;
      margin: 0px 8px;
    }
    span {
      font-size: 18px;
      color: #575757;
    }
  }
}

.fileUploadTable {
  ::-webkit-scrollbar {
    background: #f4f4f4;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #1a4ba1;
    position: static !important;
  }
  .materialPriceContentMaterial {
    display: flex;
    justify-content: start;
    gap: 35px;
    margin-top: 20px;
  }

  .materialPriceContent {
    display: flex;
    justify-content: start;
    gap: 26px;
  }
  .components {
    margin-bottom: 47px;
  }
}
.uploadFileTable {
  background-color: #7693c7;
}
.upLoadfileTopHeader {
  display: flex;
  justify-content: space-between;
  background-color: #1a4ba1;
  padding: 10px 30px;
  width: 120%;
  margin-bottom: -1px;
  color: $white;
  font-size: 14px;
  font-weight: 700;
  .baseComponent {
    width: 45%;
    padding: 5px 0px;
    display: flex;
    justify-content: center;
    border-right: 1px solid $white;
    margin-right: -45px;
  }
  .groupedAddOn {
    width: 20%;
    padding: 5px 0px;
    display: flex;
    justify-content: center;
    border-right: 1px solid $white;
  }
  .AddOnComponent {
    width: 23%;
    padding: 5px 0px;
    display: flex;
    justify-content: center;
  }
}
.uploadTable {
  width: 120% !important;
}
.retailer-management {
  .table {
    td,
    th {
      vertical-align: middle !important;
    }
  }
}

.file-management {
  .table {
    td,
    th {
      vertical-align: middle;
    }
  }
}
.retailerTable {
  width: 120% !important;
}
.maintable-content {
  height: 450px;
  z-index: 0;
}

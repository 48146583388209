@import "../../assets/scss/abstracts/abstracts";

input[type="text"]:focus {
  background-color: $white;
  box-shadow: none;
}
input[type="password"]:focus {
  background-color: $white;
  box-shadow: none;
}

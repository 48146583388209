@import "../scss/abstracts/abstracts";
html body {
  font-family: "Roboto", sans-serif;
}
button {
  &.btn {
    border-radius: 0;
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: fontSize(18px);
    height: 45px;
    padding: 12px;
    min-width: 188px;
    &.grey-btn {
      background: #eeeeee;
      border: 1px solid #eeeeee;
      color: $secondary-color;
    }
    &.normal-btn {
      background: linear-gradient(90.67deg, #1a4ba2 0%, #00b050 101.7%);
      color: $white;
      font-weight: 500;
      font-size: 17px;
      line-height: 22px;
    }
    &.grey-btn:hover {
      background: transparent;
      border: 1px solid $secondary-color;
      transition: 0.3s ease-in;
      color: $secondary-color;
    }
    &.mainbg-btn {
      background: #e3aa24;
      border: 1px solid #e3aa24;
      color: $white;
    }
    &.mainbg-btn:hover {
      background: transparent;
      border: 1px solid #e3aa24;
      transition: 0.3s ease-in;
      color: #e3aa24;
    }
    &.success-btn {
      background: $white;
      border: 1px solid #00b050;
      color: #00b050;
    }
    &.success-btn:hover {
      background: #00b050;
      border: 1px solid transparent;
      transition: 0.3s ease-in;
      color: $white;
    }
    &.outline-btn-danger {
      background: transparent;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
      border-radius: 4px;
      color: #f34141;
      min-width: auto;
      font-weight: normal;
      font-size: 14px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &.primary-btn {
      background: transparent;
      border: 1px solid $blue-2c;
      transition: 0.3s ease-in;
      color: $blue-2c;
    }
    &.primary-btn:hover {
      background: $blue-2c;
      border: 1px solid transparent;
      transition: 0.3s ease-in;
      color: $white;
    }
    &.outline-btn {
      border: 1px solid #d2d2d2;
      box-sizing: border-box;
      border-radius: 8px;
      background: $white;
      color: #2d3748;
    }
    &.outline-btn:hover {
      background: #2d3748;
      border: 1px solid #2d3748;
      color: $white;
    }
    &.outline-btn.border-none {
      background: transparent;
      border: 1px solid transparent;
      transition: 0.3s ease-in;
      color: $green-18;
    }
    &.outline-btn.border-none:hover {
      background: transparent;
      border: 1px solid $green-18;
      color: $green-18;
    }
    &.danger-btn {
      background: $white;
      border: 1px solid #f34141;
      border-radius: 8px;
      transition: 0.3s ease-in;
      color: #f34141;
    }
    &.danger-btn:hover {
      background: #f34141;
      border: 1px solid transparent;
      color: $white;
    }
    &.view-table-btn {
      background: $white;
      border: 1px solid $green-18;
      transition: 0.3s ease-in;
      font-weight: normal;
      color: $green-18;
      min-width: auto;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
      border-radius: 4px;
      font-size: 14px;
    }
    &.sortButton {
      background-color: transparent;
      font-size: 18px;
      font-weight: 400;
      color: $primary-color;
      border: 1px solid #c4c4c4;
      min-width: 100px;
    }
  }
}

.word-break-normal {
  word-break: normal;
}

.btn:focus,
.btn.focus,
input:focus {
  outline: 0;
  box-shadow: none;
}

.form-control:focus {
  color: $text-black-1a;
  border-color: #d2d2d2 !important;
  outline: 0;
  background-color: $white !important;
  box-shadow: none;
  background: none;
}
.text-black {
  color: $black;
}
.cursor-pointer {
  cursor: pointer;
}

//Hide Arrows in input
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

//Hide Arrows in input
input[type="number"] {
  -moz-appearance: textfield;
}

.text-underline {
  text-decoration: underline;
}

.modal-content {
  border: none;
  padding: 0;
  height: 100%;
}
.Select__dropdown-indicator {
  .icon-up {
    padding-bottom: 2px;
  }
}
//Retail management active inactive user
.active {
  font-size: 14px;
  font-weight: 700;
  color: #00b050;
}

.form-control {
  &:disabled {
    border-radius: 4px;
    font-size: 14px;
    line-height: 18px;
    color: $text-black-1a;
    opacity: 0.9;
    background-color: $white;
    cursor: not-allowed;
  }
}
.disabled-color {
  color: $text-black-1a;
  opacity: 0.7;
}

.transaction-infomation,
.product-maanagement{
  .select-section{
    margin-top: 0;
    min-width: 200px;
  }
}
@import "../../../assets/scss/abstracts/abstracts";

.normal-search {
  position: relative;

  .searchBox {
    border: none;
    border-radius: 0px;
    border-bottom: 1.5px solid #575757;
    width: 100%;
    font-size: fontSize(20px);
    color: $black;
    background: transparent;
    padding: 8px 24px 8px 8px;
    &:focus{
      background-color: transparent;
    }
  }

  .icon {
    svg {
      position: absolute;
      right: 0;
      top: 12px;
    }
  }
}
.searchBox {
  &::-webkit-input-placeholder {
    color: black !important;
  }
}

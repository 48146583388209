@import "./default/default.scss";
@import "./default/utility.scss";
@import "./vendors/vendors-dir.scss";
@import "./auth/login.scss";
@import "./icons/style.css";
@import "./common.scss";
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");

.font-Roboto {
  font-family: "Roboto", sans-serif;
  // font-weight: 500;
}

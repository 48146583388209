@import "../abstracts/abstracts";

.login-wrapper {
  .login-left-area {
    background-color: $primary-color;
    padding: 19px;
    height: 100%;
    min-height: 100vh;
    .logo {
      height: 62px;
      width: 62px;
    }
    .login-content-area {
      padding: 80px 60px 80px 24px;
      img {
        max-height: 325px;
        object-fit: contain;
        display: flex;
        margin: 0 auto;
      }
    }
    .btn {
      width: 188px;
    }
  }
}

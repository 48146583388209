@import "../../../assets/scss/abstracts/abstracts";

.custom-phone-input {
  margin-top: 32px;
  label {
    font-size: fontSize(12px);
    color: $blue-2c;
    line-height: 15px;
    padding-bottom: 6px;
  }
  .form-control {
    display: block;
    width: 100%;
    height: 45px;
    font-size: fontSize(16px);
    color: $text-black-1a;
    background-color: $white;
    background-clip: padding-box;
    border: 1px solid #d2d2d2;
    border-radius: 6px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  input::placeholder {
    font-size: fontSize(16px);
    letter-spacing: -0.11px;
    color: #b3b3b3;
  }
}

@import "../abstracts/abstracts";

.center-view {
  display: flex;
  align-items: center;
  flex-direction: column;
  &-login {
    @extend .center-view;
  }
  .card {
    padding: 30px;
    max-width: 375px;
    margin: auto 0;
    width: 100%;
    border-style: none;
    .shadow-lg {
      box-shadow: 0 10px 50px 10px rgba(94, 94, 94, 0.14) !important;
      border: solid 0.2px #d5d5d5;
      background-color: $white;
    }
    .logo {
      height: 175px;
      width: 310px;
      margin-left: auto;
    }
  }
  @include media-breakpoint-mobile-port {
    .card {
      min-width: 280px;
      padding: 10px;
    }
  }
  .logo {
    width: 8rem;
  }
  .login-btn {
    position: absolute;
    width: 188px;
    box-shadow: -5px 0 9px 2px rgba(0, 0, 0, 0.08);
    background-color: #222222;
    font-size: fontSize(18px);
    &:hover {
      color: $white;
    }
  }
  .forget-password {
    color: $primary-color;
    font-size: fontSize(16px);
  }
}
